import { validateCvaDvaHeadersBySchema } from '../../helpers/headerValidator';
import { ProcessableInputReport } from '../../ProcessableInputReport';
import { cvaSchemas } from './cvaReport.schemas';
import { irsMtmValuationReportHeaders } from './irsMtmValuationReportInputHeaders';

export class CvaReportValidator {
  constructor(private processableInput: ProcessableInputReport) {}

  validate() {
    const { cvaReportInputHeaders } = irsMtmValuationReportHeaders;
    const headers = cvaReportInputHeaders.map((header) => header[0]);
    const totalRowValue = this.processableInput.findCellValue('Total');

    if (!totalRowValue) {
      throw new Error(
        `Row "Total" is missing in ${this.processableInput.sheetName}`,
      );
    }

    validateCvaDvaHeadersBySchema(
      headers,
      cvaSchemas,
      this.processableInput,
      totalRowValue.row,
    );
  }
}
