import { irsMtmValuationOutputHeaders } from '../hedgehogMtmValuation/mtmValuationReportOutputHeaders';

export enum CvaDvaReportHeaders {
  CVA = 'CVA',
  DVA = 'DVA',
  RISK_FREE_VALUATION = 'Risk-free valuation',
}

export const irsMtmValuationInputHeaderTypes = {
  reference: ['Reference'],
  accruedInterest: ['Accrued interest', 'AccruedInterest'],
  effectiveDate: ['EffectiveDate'],
  maturityDate: ['Maturity date', 'MaturityDate'],
  baseCurrency: ['Base currency', 'BaseCurrency'],
  value: ['Value'],
  fixedRate: ['Fixed rate', 'FixedRate'],
  notionalAmount: ['Notional Amount', 'NotionalAmount'],
  floatingRate: ['Floating rate', 'FloatingRate'],
  nextResetDate: ['Next reset date', 'NextResetDate'],
  lastResetDate: ['Last reset date'],
  counterparty: ['Counterparty'],
  valuation: ['Valuation'],
  cva: ['CVA', 'DVA'],
  dva: ['DVA'],
  dealNumber: ['DealNumber', 'Deal Number'],
  riskFreeValuation: ['Risk-free valuation', 'Risk free valuation'],
  fairValueIncludesCva: [
    'Fair value (includes CVA)',
    'Fair value (includes DVA)',
  ],
  dealDate: ['Deal Date', 'DealDate'],
  total: ['TOTAL'],
};

export const irsMtmValuationReportHeaders = {
  detailedInstrumentReportInputsHeaders: [
    irsMtmValuationInputHeaderTypes.reference,
    irsMtmValuationInputHeaderTypes.accruedInterest,
    irsMtmValuationInputHeaderTypes.effectiveDate,
    irsMtmValuationInputHeaderTypes.dealDate,
    irsMtmValuationInputHeaderTypes.maturityDate,
    irsMtmValuationInputHeaderTypes.baseCurrency,
    irsMtmValuationInputHeaderTypes.value,
    irsMtmValuationInputHeaderTypes.counterparty,
  ],
  detailedInstrumentReportOutputsHeaders: [
    irsMtmValuationOutputHeaders.tradeReferenceNumber,
    irsMtmValuationOutputHeaders.accruedInterest,
    irsMtmValuationOutputHeaders.effectiveDate,
    irsMtmValuationOutputHeaders.tradeDate,
    irsMtmValuationOutputHeaders.maturityDate,
    irsMtmValuationOutputHeaders.currency,
    irsMtmValuationOutputHeaders.totalFairValue,
  ],
  irsPositionReportInputsHeaders: [
    irsMtmValuationInputHeaderTypes.fixedRate,
    irsMtmValuationInputHeaderTypes.notionalAmount,
    irsMtmValuationInputHeaderTypes.floatingRate,
    irsMtmValuationInputHeaderTypes.nextResetDate,
    irsMtmValuationInputHeaderTypes.lastResetDate,
    irsMtmValuationInputHeaderTypes.counterparty,
  ],
  irsPositionReportOutputHeaders: [
    irsMtmValuationOutputHeaders.fixedRate,
    irsMtmValuationOutputHeaders.notional,
    irsMtmValuationOutputHeaders.floatingRate,
    irsMtmValuationOutputHeaders.nextResetDate,
    irsMtmValuationOutputHeaders.lastResetDate,
    irsMtmValuationOutputHeaders.counterparty,
  ],
  cvaReportInputHeaders: [
    irsMtmValuationInputHeaderTypes.cva,
    irsMtmValuationInputHeaderTypes.riskFreeValuation,
  ],
  cvaReportOutputHeaders: [
    irsMtmValuationOutputHeaders.cva,
    irsMtmValuationOutputHeaders.riskFreeValuation,
  ],
  actualAndHypoStaticDataOutputHeaders: [
    irsMtmValuationOutputHeaders.tradeDate,
    irsMtmValuationOutputHeaders.valuationCurrency,
    irsMtmValuationOutputHeaders.timeValue,
    irsMtmValuationOutputHeaders.intrinsicValue,
    irsMtmValuationOutputHeaders.legalEntity,
    irsMtmValuationOutputHeaders.swapIndex,
  ],
  actualAndHypoOutputHeadersForTotalRowFormulas: [
    irsMtmValuationOutputHeaders.accruedInterest,
    irsMtmValuationOutputHeaders.cleanFairValue,
    irsMtmValuationOutputHeaders.totalFairValue,
    irsMtmValuationOutputHeaders.cva,
    irsMtmValuationOutputHeaders.dva,
    irsMtmValuationOutputHeaders.riskFreeValuation,
    irsMtmValuationOutputHeaders.valuationCurrency,
  ],
};
