import * as Joi from 'joi';
import { irsMtmValuationInputHeaderTypes } from './irsMtmValuationReportInputHeaders';

export const cvaSchemas: Record<string, Joi.Schema> = {
  [irsMtmValuationInputHeaderTypes.cva[0]]: Joi.number().required().messages({
    'number.base': 'CVA must be a valid number and cannot be null or empty',
  }),
  [irsMtmValuationInputHeaderTypes.dva[0]]: Joi.number().required().messages({
    'number.base': 'DVA must be a valid number and cannot be null or empty',
  }),
  [irsMtmValuationInputHeaderTypes.riskFreeValuation[0]]: Joi.number()
    .required()
    .messages({
      'number.base':
        'Risk Free Valuation must be a valid number and cannot be null or empty',
      'any.required': 'Risk Free Valuation must be provided',
    }),
};
