import { IStaticField } from '../../../services/hedgehog/types/OutputReportTypes';
import { IProfileConfigSegment } from './interface/IProfileSegment';

export enum ProfileConfigStaticDataName {
  General = 'General',
  IrsHedgeDocumentationForm = 'IRS Hedge Documentation Form',
  FxCurrencyRules = 'FX Currency Rules',
}

const irsHedgeDocumentationFormConfigSegments: IProfileConfigSegment[] = [
  {
    title: 'IRS Hedge Documentation Form Static Data',
    name: ProfileConfigStaticDataName.IrsHedgeDocumentationForm,
    fields: [
      {
        name: 'hedgeDocumentationFormStaticData.natureOfHedgedRisk',
        label: 'Nature of Risk Hedged',
        type: 'textarea',
        rows: 2,
      },
      {
        name: 'hedgeDocumentationFormStaticData.preparedBy',
        label: 'Prepared By',
        type: 'string',
      },
      {
        name: 'hedgeDocumentationFormStaticData.reviewedBy',
        label: 'Reviewed By',
        type: 'string',
      },
    ],
  },
];
const fxSegmentConfig: IProfileConfigSegment[] = [
  {
    title: 'FX Currency Rules Static Data',
    name: ProfileConfigStaticDataName.FxCurrencyRules,
    fields: [],
  },
];

const generalConfigSegmentsForBoth: IProfileConfigSegment[] = [
  {
    title: 'Organization Profile Static Data',
    name: ProfileConfigStaticDataName.General,
    fields: [
      {
        name: 'legalEntity',
        label: 'Legal Entity',
        type: 'string',
      },
      {
        name: 'currency',
        label: 'Currency',
        type: 'select',
        options: [
          'AUD',
          'NZD',
          'GBP',
          'JPY',
          'DKK',
          'EUR',
          'ZAR',
          'CAD',
          'USD',
        ],
        defaultValue: 'AUD',
      },
    ],
  },
];

export const irsOnlySegmentProfile: IProfileConfigSegment[] = [
  ...generalConfigSegmentsForBoth,
  ...irsHedgeDocumentationFormConfigSegments,
];

export const fxOnlySegmentProfile: IProfileConfigSegment[] = [
  ...generalConfigSegmentsForBoth,
  ...fxSegmentConfig,
];

export const segmentConfigProfileForBoth: IProfileConfigSegment[] = [
  ...generalConfigSegmentsForBoth,
  ...irsHedgeDocumentationFormConfigSegments,
  ...fxSegmentConfig,
];

export const generalFieldsForHedgeDocFormSegment: IStaticField[] = [
  {
    name: 'natureOfHedgedRisk',
    label: 'Nature of Risk Hedged',
    type: 'textarea',
    rows: 2,
  },
  {
    label: 'Policy Declaration',
    name: 'policyDeclaration',
    type: 'textarea',
    rows: 1,
  },
  {
    label: 'Risk Management Strategy',
    name: 'strategy',
    type: 'textarea',
    rows: 2,
  },
  {
    label: 'Risk Management Objective',
    name: 'riskManagementObjective',
    type: 'textarea',
    rows: 2,
  },
  {
    label: 'Cash Flow Hedge',
    name: 'cashFlowHedge',
    rows: 5,
    type: 'radio',
    options: ['Yes', 'No'],
  },
  {
    label: 'Fair Value Hedge',
    name: 'fairValueHedge',
    type: 'radio',
    options: ['Yes', 'No'],
  },
  {
    label: 'Net Investment Hedge',
    name: 'netInvestmentHedge',
    type: 'radio',
    options: ['Yes', 'No'],
  },
  {
    label: 'Nature of hedging instrument',
    name: 'natureOfHedgingInstrument',
    type: 'textarea',
  },
  {
    label: 'Amount of principal designated as hedging instrument (Percentage)',
    name: 'amountOfPrincipalHedgingInstrumentPercentaje',
    type: 'number',
  },
  {
    name: 'natureOfHedgedItem',
    label: 'Nature of Hedged Item',
    type: 'textarea',
    rows: 2,
  },
  {
    label: 'Impact to profit or loss',
    name: 'impactToProfitOrLoss',
    type: 'textarea',
  },
  {
    label: 'Contractual parties of the hedging item',
    name: 'contractualPartiesOfHedgingItem',
    type: 'string',
  },
  {
    label: 'Amount designated as a hedged item (Percentage)',
    name: 'amountDesignatedAsHedgedItemPercentage',
    type: 'number',
  },
  {
    name: 'preparedBy',
    label: 'Prepared By',
    type: 'string',
  },
  {
    name: 'reviewedBy',
    label: 'Reviewed By',
    type: 'string',
  },
];

export const fxHedgeDocFormSyntheticTemplate: IProfileConfigSegment = {
  title: 'FX Hedge Documentation Form Static Data',
  name: 'Segment',
  fields: [
    ...generalFieldsForHedgeDocFormSegment,
    {
      label:
        'To achieve hedge effectiveness - Explanation (Synthetic Legs Template)',
      name: 'toAchieveHedgeEffectivenessExplanationSyntheticTemplate',
      type: 'textarea',
    },
    {
      label: 'Underlying risk(s) - Hedged Item (i)',
      name: 'underlyingRiskHedgedItemI',
      type: 'string',
    },
    {
      label: 'Underlying risk(s) - Hedged Item (ii)',
      name: 'underlyingRiskHedgedItemII',
      type: 'string',
    },
  ],
};

export const fxHedgeDocFormGeneralTemplate: IProfileConfigSegment = {
  title: 'FX Hedge Documentation Form Static Data',
  name: 'Segment',
  fields: generalFieldsForHedgeDocFormSegment,
};
